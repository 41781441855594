/** @jsxImportSource @emotion/react */

import { Form, Upload, UploadFile, UploadProps } from "antd";
import { ValidateStatus } from "antd/es/form/FormItem";
import { ReactComponent as UploadIcon } from "assets/Common/icons/upload.svg";
import { Control, Controller, FieldValues } from "react-hook-form";

import { InputFileStyle } from "./style.index";

interface InputFileProps {
  name?: string;
  control?: Control<FieldValues | any>;
  error?: string;
  validate_status?: ValidateStatus;
  disabled?: boolean;
  accept?: string;
  placeholder?: string;
  onChange?: (file: UploadFile) => void;
  file?: UploadFile | null;
  isDeposit?: boolean;
  id?: string;
}

const InputFile = (props: InputFileProps) => {
  const {
    id = "",
    name = "",
    accept = "image/*",
    placeholder = "Choose file",
    control,
    error = "",
    validate_status,
    disabled,
    onChange,
    file,
    isDeposit = false,
  } = props;

  const uploadProps: UploadProps = {
    beforeUpload: (file) => {
      onChange && onChange(file);

      return false;
    },
    showUploadList: false,
    fileList: file ? [file] : [],
    disabled,
    accept,
  };

  return (
    <div css={InputFileStyle.self}>
      {control ? (
        <Form.Item help={error} validateStatus={validate_status}>
          <Controller
            name={name}
            control={control}
            render={({ field: { onChange: _onChange } }) => {
              // const isError = Object.keys(errors).length > 0;

              return (
                <Upload
                  {...uploadProps}
                  onChange={({ file }) => {
                    _onChange(file);
                  }}
                >
                  <div
                    id={id}
                    css={[
                      InputFileStyle.content(isDeposit),
                      error && InputFileStyle.contentError,
                    ]}
                  >
                    <span css={InputFileStyle.fileName}>
                      {file?.name || (
                        <span
                          css={[
                            InputFileStyle.placeholder(isDeposit),
                            error && InputFileStyle.placeholderError,
                          ]}
                        >
                          {placeholder}
                        </span>
                      )}
                    </span>
                    <span
                      css={[
                        InputFileStyle.iconUpload(isDeposit),
                        error && InputFileStyle.iconUploadError,
                      ]}
                    >
                      <UploadIcon />
                    </span>
                  </div>
                </Upload>
              );
            }}
          />
        </Form.Item>
      ) : (
        <Upload {...uploadProps}>
          <div css={InputFileStyle.content(isDeposit)}>
            <span css={InputFileStyle.fileName}>
              {file?.name || (
                <span css={InputFileStyle.placeholder(isDeposit)}>
                  {placeholder}
                </span>
              )}
            </span>
            <span css={InputFileStyle.iconUpload(isDeposit)}>
              <UploadIcon />
            </span>
          </div>
        </Upload>
      )}
    </div>
  );
};

export default InputFile;
